import Seo from "../../components/_App/seo"
import Navbar from "../../components/_App/Navbar"
import React, { useEffect, useState } from "react"
import Layout from "../../components/_App/layout"
import Breadcrumbs from "../../components/Common/Breadcrumbs"
import * as convertStyled from "../../assets/css/convert-case.module.css"
import DownloadAndCopyButtons from "../../helpers/buttons"
import CounterText from "../../helpers/CounterText"
import Partner from "../../components/Common/Partner"
import MilestonesAchievedSoFar from "../../components/Common/MilestonesAchievedSoFar"
import Footer from "../../components/_App/Footer"
import CopiedMessage from "../../helpers/CopiedMessage"

const parent = [{
  name: "Tools",
  link: 'tools',
}];

function sentenceToBinary(sentence) {
  let binaryString = '';
  for (let i = 0; i < sentence.length; i++) {
    const charCode = sentence.charCodeAt(i);
    const binaryCode = charCode.toString(2).padStart(8, '0');
    binaryString += binaryCode + ' ';
  }
  return binaryString.trim();
}

function WideTextGenerator() {

  const [textFieldValue, setTextFieldValue] = useState("");
  const [previewValue, setPreviewValue] = useState('');
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [message, setMessage] = useState("");


  function handleTextField(e) {
    setTextFieldValue(e.target.value);
    setPreviewValue(sentenceToBinary(e.target.value));
  }

  useEffect(() => {
    setIsInputEmpty(textFieldValue === "");
  }, [textFieldValue])

  const showMessage = (msg) => {
    setMessage(msg);
  };

  return (
    <Layout>
      <Seo title="Binary Code Converter Tool"/>
      <Navbar/>
      <div className="contact-area ptb-80">
        <div className="container py-5">
          <div className="section-title">
            <h1>Binary Text Generator</h1>
            <div className="bar"/>
          </div>
          <Breadcrumbs pageTitle="Binary Text Generator" parent={parent} />
          <div className="row align-items-center">
            <div className="row">
              <div className="col-lg-6">
                <textarea name="" value={textFieldValue}  onChange={(e) => handleTextField(e)} className={convertStyled.text_field} id="" cols="30" rows="10"/>
                <CounterText text={textFieldValue} />
              </div>
              <div className="col-lg-6">
                <textarea name="" value={previewValue} className={`${convertStyled.text_field} ${convertStyled.wideText}`} id="" cols="30" rows="10"/>
                <DownloadAndCopyButtons previewValue={previewValue} disabled={isInputEmpty} showMessage={showMessage} />
                {message && <CopiedMessage message={message} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <MilestonesAchievedSoFar />
      <Footer />
    </Layout>
  )
}

export default WideTextGenerator
